import './Icon.scss'

import cn from 'classnames'

export type IProps = {
  className?: string
  width?: string | number
  height?: string | number
  name: string
}

const Icon = ({ className, name = 'github-icon', width = '24', height = '24' }: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${cn('icon', {
        [`${className}`]: !!className,
      })}`}
    >
      <use xlinkHref={`/sprite/sprite-10/symbol/svg/sprite.css.svg#${name}`} />
    </svg>
  )
}

export default Icon
