import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/src/app/fonts/index.ts\",\"import\":\"Geologica\",\"arguments\":[{\"variable\":\"--font-geologica\",\"weight\":[\"400\",\"500\",\"600\",\"700\",\"900\"],\"style\":[\"normal\"],\"subsets\":[\"latin\",\"cyrillic\"],\"display\":\"swap\"}],\"variableName\":\"GeologicaFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/src/app/scss/index.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/layout.scss");
