'use client'

import './MobileNavigation.scss'

import cn from 'classnames'
import classNames from 'classnames'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useLocale, useTranslations } from 'next-intl'
import { useEffect } from 'react'

import { usePathname as usePathnameLocalized } from '@/src/app/config/i18n'
import { locales, pathnames } from '@/src/app/config/pathnames'
import { useFullUrl } from '@/src/shared/hooks/useFullUrl'
import ImageLogo from '@/src/shared/images/logo-white.svg'
import { INavigationKey } from '@/src/shared/model'
import { Image } from '@/src/shared/ui/Image'
import { LocalizedLink } from '@/src/shared/ui/LocalizedLink'
import { useMobileMenuContext } from '@/src/widgets/MobileNavigation/context'

import { ShowFeedbackModalButton } from '../../Feedback/ui/ShowFeedbackModalButton'

export type IMobileNavigationProps = {
  className?: string
  navigation: {
    title: string
    href: keyof typeof pathnames
    key: INavigationKey
  }[]
}

const MobileNavigation = ({ className, navigation = [] }: IMobileNavigationProps) => {
  const { mobileMenuIsOpen, onMobileMenuChange } = useMobileMenuContext()
  const t = useTranslations()
  const pathname = usePathname()
  const localizedPathname = usePathnameLocalized()
  const { fullUrl } = useFullUrl()
  const currentLocale = useLocale()

  useEffect(() => {
    if (mobileMenuIsOpen) {
      onMobileMenuChange(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      className={`${cn('mobile-navigation', {
        [`${className}`]: !!className,
        'mobile-navigation--opened': mobileMenuIsOpen,
      })}`}
    >
      <nav className="mobile-navigation__inner">
        <LocalizedLink href="/" className="mobile-navigation__logo">
          <Image
            src={ImageLogo}
            aspectRatio={{
              width: 106,
              height: 36,
            }}
            alt={t('Header.title')}
          />
        </LocalizedLink>
        <div className="mobile-navigation__list-wrap">
          <ul className="mobile-navigation__list">
            {navigation.map(({ title, href, key }) => (
              <li key={key} className="mobile-navigation__item">
                <LocalizedLink
                  href={href}
                  className={cn('mobile-navigation__item-link', {
                    'mobile-navigation__item-link--active': localizedPathname.startsWith(href),
                  })}
                >
                  {title}
                </LocalizedLink>
              </li>
            ))}
          </ul>

          <div className="mobile-navigation__button">
            <ShowFeedbackModalButton buttonText={t('Common.becomePartner')} />
          </div>

          <ul className="mobile-navigation__locales">
            {locales.map((lng) => (
              <li key={lng} className="mobile-navigation__locale">
                <Link
                  scroll={false}
                  href={`/${lng}/${fullUrl}`}
                  className={classNames('mobile-navigation__locale-link', {
                    'mobile-navigation__locale-link--active': currentLocale === lng,
                  })}
                >
                  {lng}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default MobileNavigation
