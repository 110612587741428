'use client'

import './Spotify.scss'

import cn from 'classnames'
import { useRef, useState } from 'react'
import { useKey } from 'react-use'

import useClickAway from '@/src/shared/hooks/useClickAway'
import { Button } from '@/src/shared/ui/Button'

import { SpotifyPlayer } from './SpotifyPlayer'

const Spotify = () => {
  const [isOpen, setDropState] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const [isPlaying, setPlaySpotify] = useState(false)

  function handleToggle(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    setDropState(() => !isOpen)
  }

  useClickAway(() => {
    setDropState(false)
  }, ref)

  useKey('Escape', () => setDropState(false))

  const handlePlaySpotify = (isPlaying: boolean) => setPlaySpotify(isPlaying)

  return (
    <section
      ref={ref}
      className={cn('spotify-dropdown', {
        'spotify-dropdown--is-open': isOpen,
        'spotify-dropdown--is-play': isPlaying,
      })}
    >
      <a className="spotify-dropdown__toggler" onClick={(e) => handleToggle(e)} href="#">
        <Button
          tag="div"
          variant="secondary"
          squared
          size="s"
          iconName={isPlaying ? 'play-music-icon' : 'play-icon'}
        />
      </a>

      <div className="spotify-dropdown__body">
        <div className="spotify-dropdown__body-inner">
          <SpotifyPlayer onPlay={handlePlaySpotify} />
        </div>
      </div>
    </section>
  )
}

export default Spotify
