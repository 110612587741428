import { SpotifyPlayerController } from 'global'
import { useEffect, useRef, useState } from 'react'

import { isLightHouse } from '@/src/shared/lib/detectDevice'

type SpotifyPlayerProps = {
  onPlay: (isPlaying: boolean) => void
}

export const SpotifyPlayer = ({ onPlay }: SpotifyPlayerProps) => {
  const iframeRef = useRef<HTMLDivElement>(null)
  const [player, setPlayer] = useState<SpotifyPlayerController | null>(null)

  const initializePlayer = () => {
    setTimeout(() => {
      window.onSpotifyIframeApiReady = (IFrameAPI) => {
        const options = {
          uri: `spotify:playlist:2kVDAMVKRT5JD0hBgQNqcQ`,
          height: 500,
          width: 345,
        }

        if (iframeRef.current) {
          const callback = (EmbedController: SpotifyPlayerController) => {
            setPlayer(EmbedController)

            EmbedController.addListener('playback_update', (e: { data: { isPaused: boolean } }) => {
              onPlay(!e.data.isPaused)
            })
          }

          IFrameAPI.createController(iframeRef.current, options, callback)
        }
      }
    }, 500)
  }

  useEffect(() => {
    if (isLightHouse) return

    const script = document.createElement('script')
    script.src = 'https://open.spotify.com/embed/iframe-api/v1'
    script.async = true

    document.body.appendChild(script)

    if (!player) initializePlayer()

    return () => {
      if (player) {
        player.removeListener('playback_update')
        player.destroy()
      }

      document.body.removeChild(script)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div ref={iframeRef}></div>
}
