export const HOST = process.env.NEXT_PUBLIC_HOST ?? ''
export const HOST_PLAYTEST = process.env.NEXT_PUBLIC_PLAYTEST_HOST ?? ''
export const NODE_ENV = process.env.NODE_ENV ?? ''
export const API_URL = process.env.NEXT_PUBLIC_API_URL ?? ''
export const API_URL_PLAYTEST = process.env.NEXT_PUBLIC_PLAYTEST_API_URL ?? ''
export const STORYBOOK = process.env.STORYBOOK ?? ''
export const GTAG = process.env.NEXT_PUBLIC_GTAG ?? ''
export const RECAPTCHA = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? ''
export const TG_LOGIN_BOT_NAME = process.env.NEXT_PUBLIC_TG_LOGIN_BOT_NAME ?? ''

export const isDevEnv = NODE_ENV === 'development'
export const isProdEnv = NODE_ENV === 'production'

export const isStorybook = STORYBOOK || false

export const isAnalyticsEnabled = (GTAG && !isStorybook) || false
export const isAnalyticsDisabled = !isAnalyticsEnabled
