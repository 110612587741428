import { apiClient, apiClientPlaytest } from '@/src/shared/api'

import { ISession } from '../model'
import { ISessionDto } from './dto'
import { mapSession } from './mapper'

export const getSession = async (): Promise<ISession> => {
  const res = await apiClient.get<ISessionDto>('session')

  return mapSession(res)
}

export const getSessionPlaytest = async (): Promise<ISession> => {
  const res = await apiClientPlaytest.get<ISessionDto>('session')

  return mapSession(res)
}
