/* eslint-disable @typescript-eslint/no-unused-vars */
'use client'

import classNames from 'classnames'
import { useTranslations } from 'next-intl'
import { createContext, Suspense, useContext } from 'react'
import { useToggle } from 'react-use'

import { navigation } from '@/src/shared/config/navigation'
import { MobileNavigation } from '@/src/widgets/MobileNavigation/ui'

const MobileMenuContext = createContext({
  mobileMenuIsOpen: false,
  onMobileMenuChange: (isOpen: boolean): void => {},
})

export const MobileMenuProvider = ({ children }: { children: React.ReactNode }) => {
  const t = useTranslations()
  const [on, toggle] = useToggle(false)

  return (
    <MobileMenuContext.Provider value={{ mobileMenuIsOpen: on, onMobileMenuChange: toggle }}>
      {children}

      <div className={classNames('layout__mobile-navigation')}>
        <Suspense>
          <MobileNavigation
            navigation={navigation.map(({ key, href }) => ({
              key,
              title: t(`Header.navigation.${key}`),
              href,
            }))}
          />
        </Suspense>
      </div>
    </MobileMenuContext.Provider>
  )
}

export const useMobileMenuContext = () => useContext(MobileMenuContext)
