import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'

import { isClient } from '@/src/shared/lib/isServerOrClient'

const getFullUrl = (location: Location) => {
  const [, , ...segments] = location.pathname.split('/')
  const pathname = segments.join('/')
  const search = location.search

  return search.length ? `${pathname}${search}` : pathname
}

export const useFullUrl = () => {
  const [fullUrl, setFullUrl] = useState('')
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    if (isClient) {
      const url = getFullUrl(window.location)

      setFullUrl(url)
    }
  }, [searchParams, pathname])

  return { fullUrl }
}
