/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import './Video.scss'

import classNames from 'classnames'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useInViewport } from 'react-in-viewport'
import { useScrolling } from 'react-use'

import { BreakpointsSm } from '../../config/variables'
import { useAspectRatio } from '../../hooks/useAspectRatio'
import { isClient } from '../../lib/isServerOrClient'
import { IAspectRatio } from '../../model/aspectRatio'
import { Image } from '../Image'

export type IVideo = {
  autoplay?: boolean
  poster?: string | null | StaticImport
  posterMobile?: string | null | StaticImport
  src: string
  srcMobile?: string | null
  priority?: boolean
  aspectRatio: IAspectRatio
  sizes?: string
  onLoaded?: (id: string) => void
}

const _window = isClient ? window : null

export type IVideoImperativeHandlers = {
  play: () => void
  pause: () => void
  load: () => void
}

const Video = forwardRef<IVideoImperativeHandlers, IVideo>(
  (
    {
      src,
      srcMobile,
      aspectRatio,
      onLoaded,
      poster,
      priority,
      posterMobile,
      sizes,
      autoplay = true,
    },
    ref,
  ) => {
    const { id, styles } = useAspectRatio(aspectRatio)

    const videoRef = useRef<HTMLVideoElement>(null)
    const viewportRef = useRef<HTMLDivElement>(null)
    const documentRef = useRef(_window as unknown as HTMLElement)

    const [canPlay, setCanPlayState] = useState(false)
    const [isPlayed, setPlayed] = useState(false)

    const scrolling = useScrolling(documentRef)
    const { inViewport } = useInViewport(viewportRef, { threshold: 0.45 })

    const handleCanPlay = () => {
      setCanPlayState(true)
    }

    const handleLoaded = () => {
      onLoaded?.(id)
    }

    useImperativeHandle(ref, () => {
      const video = videoRef.current

      return {
        play() {
          video?.play()
        },
        pause() {
          video?.pause()
        },
        load() {
          video?.load()
        },
      }
    })

    useEffect(() => {
      const video = videoRef.current

      if (!video) return
      if (!autoplay) return

      if (inViewport && !scrolling) {
        if (video.paused) {
          video.play().then(() => {
            setPlayed(true)
          })
        }
      } else {
        if (video.played?.length) {
          video.pause()
          setPlayed(false)
        }
      }

      return () => {
        video?.pause()
        // video.currentTime = 0
      }
    }, [inViewport, scrolling, autoplay])

    useEffect(() => {
      const video = videoRef.current

      if (autoplay || canPlay) return

      if (inViewport && !scrolling) video?.load()
    }, [inViewport, scrolling, autoplay])

    return (
      <>
        {styles}

        <div
          ref={viewportRef}
          className={classNames('video', {
            'video--loaded': canPlay,
            'video--is-played': isPlayed,
          })}
        >
          <div data-id={id} className="video__inner">
            <video
              ref={videoRef}
              onLoadedData={handleLoaded}
              onCanPlay={handleCanPlay}
              playsInline
              loop
              muted
              width="100%"
              height="auto"
              controls={false}
              preload="none"
            >
              <source media={srcMobile ? `(min-width: ${BreakpointsSm})` : ''} src={src} />
              {srcMobile && <source src={srcMobile ?? ''} />}
            </video>

            <Image
              priority={priority}
              fillParent
              src={poster}
              srcMobile={posterMobile}
              aspectRatio={aspectRatio}
              sizes={sizes}
              alt={`poster-${id}`}
            />
          </div>
        </div>
      </>
    )
  },
)

Video.displayName = 'Video'

export default Video
