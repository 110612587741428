/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import './HighlightCard.scss'

import cn from 'classnames'

import { Link } from '@/src/app/config/i18n'
import { BreakpointsLg, BreakpointsSm } from '@/src/shared/config/variables'
import { formatLink } from '@/src/shared/lib/formatLink'
import { Title } from '@/src/shared/ui/Title'
import { VideoAndImage } from '@/src/shared/ui/VideoAndImage'

import { IHighlight } from '../../model'

export type IHighlightCardProps = {
  className?: string
  highlight?: IHighlight
  loading?: boolean
}

const HighlightCard = ({ className, highlight, loading }: IHighlightCardProps) => {
  if (loading || !highlight) {
    return (
      <HighlightCard
        className="highlight-card--loading"
        highlight={{
          id: 0,
          image: null,
          video: null,
          link: null,
          title: '4tuna for life tee',
        }}
      />
    )
  }

  const { link, video, image, title } = highlight

  const { href, internalLink } = formatLink(link)
  const Wrapper = href ? Link : 'article'

  return (
    <Wrapper
      className={`${cn('highlight-card', {
        [`${className}`]: !!className,
      })}`}
      href={href as any}
      target={internalLink ? '_self' : '_blank'}
    >
      <div className="highlight-card__media">
        <div className="highlight-card__media-inner">
          <VideoAndImage
            sizes={`(max-width: ${BreakpointsSm}) 100vw, (max-width: ${BreakpointsLg}) 50vw, 33vw`}
            image={image}
            video={video}
            aspectRatio={{
              width: 538,
              height: 289,
            }}
            alt={title}
          />
        </div>
      </div>

      <div className="highlight-card__title">
        <Title size="xs" weight="l" ellipsisRows="two" tag="h3">
          {title}
        </Title>
      </div>
    </Wrapper>
  )
}

export default HighlightCard
