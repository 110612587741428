import { INavigation } from '../model'

export const navigation: INavigation[] = [
  {
    key: 'slots',
    href: '/games',
  },
  // {
  //   key: 'tournaments',
  //   href: '/tournaments',
  // },
  {
    key: 'store',
    href: '/store',
  },
  {
    key: 'studio',
    href: '/studio',
  },
]
