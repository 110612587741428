'use client'

import './Navigation.scss'

import classNames from 'classnames'

import { usePathname } from '@/src/app/config/i18n'
import { pathnames } from '@/src/app/config/pathnames'
import { LocalizedLink } from '@/src/shared/ui/LocalizedLink'
import { INavigationKey } from '@/src/widgets/Header/model'

type INavigationProps = {
  navigation: {
    title: string
    href: keyof typeof pathnames
    key: INavigationKey
  }[]
}

const Navigation = ({ navigation }: INavigationProps) => {
  const localizedPathname = usePathname()

  return (
    <nav className="header__navigation">
      <ol className="header__navigation-list">
        {navigation.map(({ title, href, key }) => (
          <li key={key} className="header__navigation-item">
            <LocalizedLink
              href={href}
              className={classNames('header__navigation-link', {
                'header__navigation-link--active': localizedPathname.startsWith(href),
              })}
            >
              {title}
            </LocalizedLink>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Navigation
