'use client'

import './MenuToggler.scss'

import { Spiral as Hamburger } from 'hamburger-react'

import { ColorSemanticThird } from '@/src/shared/config/variables'

import { useMobileMenuContext } from '../../../MobileNavigation/context'

const MenuToggler = () => {
  const { mobileMenuIsOpen, onMobileMenuChange } = useMobileMenuContext()

  return (
    <Hamburger
      color={ColorSemanticThird}
      size={24}
      direction="left"
      toggled={mobileMenuIsOpen}
      toggle={(state) => onMobileMenuChange(state as boolean)}
    />
  )
}

export default MenuToggler
