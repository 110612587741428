import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'

import { SESSION_TOKEN_KEY_NAME } from '@/src/app/config/constants'

type ISessionToken = {
  cookies?: () => ReadonlyRequestCookies
  sessionTokenKeyName?: string
  token?: string | null
}

export const getSessionToken = ({ sessionTokenKeyName, cookies }: ISessionToken = {}) => {
  const keyName = sessionTokenKeyName ?? SESSION_TOKEN_KEY_NAME

  if (cookies) {
    return getCookie(keyName, { cookies }) ?? null
  }

  return getCookie(keyName) ?? null
}

export const setSessionToken = ({ sessionTokenKeyName, token }: ISessionToken = {}) => {
  const keyName = sessionTokenKeyName ?? SESSION_TOKEN_KEY_NAME

  setCookie(keyName, token, {
    maxAge: 60 * 60 * 24 * 365,
    sameSite: 'none', // TODO небезопасно, подумать об этом
    secure: true,
  })

  return token
}

export const deleteSessionToken = ({ sessionTokenKeyName }: ISessionToken = {}) => {
  const keyName = sessionTokenKeyName ?? SESSION_TOKEN_KEY_NAME

  deleteCookie(keyName)
}
